<template>
    <div class="post-list-ctn">
        <a-spin v-show="mainContentLoading && postList.length == 0" :spinning="mainContentLoading">
            <div style="padding: 16px;">
                <a-skeleton active avatar title :paragraph="{ rows: 4 }" />
            </div>
        </a-spin>
        <a-spin :spinning="mainContentLoading && postList.length !== 0" tip="正在加载中...">
            <div ref="listRef">
                <div v-for="(item, index) in postList">
                    <PostItem :index="index" :postData.sync="item" :isSelf="isSelf" @onDelete=" onDelete(index)"></PostItem>
                </div>
            </div>
        </a-spin>
        <div v-if="!mainContentLoading && postList.length === 0" style="padding: 50px;">
            <a-empty></a-empty>
        </div>
        <div class="pagination-ctn default-pagination">
            <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total"
                show-quick-jumper hideOnSinglePage style="margin: 25px;"/>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue-demi';
import PostItem from '../../components/postItem.vue';
import { getUserPublishPaper } from '@/api/paperManage.js';

export default {
    props: {
        userInfo: {
            default: () => ({})
        }
    },
    components: {
        PostItem
    },
    setup(props, context) {
        const current = ref(1); // 当前页
        const total = ref(0); // 总页数
        const pageSize = ref(20); // 单页数目
        const mainContentLoading = ref(true);   // 是否正在加载
        const postList = ref([]);   // 帖子列表

      /*   const { $route } */

        // 页数改变
        const handlePageChange = async () => {
            mainContentLoading.value = true
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            const { $route } = context.root;
            const res = await getUserPublishPaper({
                pagesize: pageSize.value,
                pageno: current.value,
                userId: $route.params.id
            });
            if(res.success) {
                if(res.data.pageResult) {
                    postList.value = res.data.pageResult.rows.map(post => {
                        return {
                            ...post,
                            avatar: props.userInfo.avatar,
                            userName: props.userInfo.name,
                            userId: props.userInfo.id
                        }
                    });
                    total.value = res.data.pageResult.totalRows
                    context.emit('onChangePostCount', total.value,  res.data.commentpapers)
                }
                else {
                    postList.value = []
                    total.value= 0;
                    context.emit('onChangePostCount', total.value,  res.data.commentpapers)
                }
            }
            mainContentLoading.value = false;
           /*  setTimeout(() => {
                postList.value = [1,2,3,4,5,6,7,8,9,10]
                mainContentLoading.value = false;
                total.value = 1000;
            }, 2000) */
        }
        // 
        onMounted(() => {
            document.title = `帖子列表 - ${props.userInfo.name}`
            handlePageChange();
        })

        const { $route } = context.root;

        const isSelf = computed(() => {
            return $route.params.id == 0;
        })

        const onDelete = async (index) => {
            postList.value.splice(index,1);
            await handlePageChange();
            context.emit('onChangePostCount', total.value,  res.data.commentpapers)
        }

        return {
            mainContentLoading,
            postList,
            current,
            total,
            pageSize,
            handlePageChange,
            isSelf,
            onDelete
        }
    }
}
</script>

<style lang="less" scoped>
.pagination-ctn {
            //padding: 25px;
            text-align: center;
            //border-left: .5px solid #ebebeb;
            // border-right: .5px solid #ebebeb;
            //border-bottom: .5px solid #ebebeb;
        }
</style>